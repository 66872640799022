<template>
  <section class="projects container-fluid align-center px-6 pt-6 pb-0">
    
    <v-row :gutters="3" pa-2>
       <div class="ticket-overlay" @click="isVisible=!isVisible" v-show="isVisible"></div>

      <v-col class="sidebar-container" style="max-width: 300px !important">
        <div class="sidebar"     :class="isVisible ? 'visible':''" >
          <h3>{{$t('Pages') }}</h3> 
          <v-list>
            <v-list-item-group v-model="model" multiple>
              <template v-for="(item, i) in projects">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item"
                  active-class="primary--text text--accent-4"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.project_name"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        color="primary accent-4"
                      ></v-checkbox>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template> </v-list-item-group
          ></v-list>
          <h4>{{$t('Categories') }}</h4>

          <v-treeview
            v-if="categories[0]"
            v-model="filters.categoryId"
            selectable
            item-disabled="locked"
            :items="categories"
          ></v-treeview>
        </div>
      </v-col>
      <v-col>
        <v-card v-if="!dialog">
          <v-card-title>
            <v-btn
              color="primary"
               @click="isVisible=!isVisible"
              depressed
              large
             
              class="btn-mobile"
            > FILTER
            </v-btn>
            <v-spacer></v-spacer>
            <div   style=" margin-left: 10px; margin-top:-12px;">
            <v-text-field
              v-model="filters.keyword"
              append-icon="mdi-magnify"
              :label="$t('Search')"
              single-line
              hide-details
              clearable
            ></v-text-field>
            </div>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="pages"
            class="elevation-1 page-table"
            :page.sync="filters.page"
            :items-per-page="filters.rowsPerPage"
            :sort-by.sync="filters.sort.name"
            :sort-desc.sync="filters.sort.desc"
            :server-items-length="total"
            :loading="loading"
            :footer-props="{
              'items-per-page-options': [25, 50, 100],
            }"
          >
            >
            <template v-slot:item.record_id="{ item }">
              <a @click="openPage(item)">
                {{ item.record_id }} - {{ total }}
              </a>
            </template>
            <template v-slot:item.title="{ item }">
              <a @click="openPage(item)">
                {{ item.title }}
              </a>
            </template>
            <template v-slot:item.rel_created_by_label="{ item }">
              {{ item.rel_created_by_label }}
            </template>
          </v-data-table>
        </v-card>
        <div v-else>
          <v-btn
            color="primary"
            depressed
            rounded
            small
            @click="close()"
            >{{$t('Back to Pages') }}
          </v-btn>

          <v-breadcrumbs :items="breadcrumbs">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <v-card>
            <v-card-title>
              <div class="page-detail-title">
                <h3><i class="fas fa-books"></i> {{ selected.title }}</h3>
              </div>
              <div class="page-detail-subtitle">
                {{$t('Created by:') }}
                {{
                  (pageDetail.last_modified_by &&
                    pageDetail.last_modified_by.label) ||
                  (pageDetail.created_by && pageDetail.created_by.label)
                }}
                on {{ pageDetail.last_modified_on || pageDetail.created_on }}
              </div>
            </v-card-title>
            <div
              class="pa-6"
              v-if="pageDetail.page_content"
              v-html="pageDetail.page_content"
            ></div>
            <div class="pa-12" v-else>{{$t('Content is empty.') }}</div>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <!-- <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ selected.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
       <div class="pa-12" >

          Created by: {{pageDetail.last_modified_by && pageDetail.last_modified_by.label || pageDetail.created_by && pageDetail.created_by.label}} on {{pageDetail.last_modified_on || pageDetail.created_on}}

       </div>
       <div class="pa-12" v-if="pageDetail.page_content" v-html="pageDetail.page_content"></div>
       <div class="pa-12" v-else>Content is empty.</div>
      </v-card>
    </v-dialog> -->
  </section>
</template>

<script>
import { ProjectProxy } from "@/proxies/ProjectProxy";
import { PagesProxy, PageProxy } from "@/proxies/PagesProxy";
// import { CollectionProxy } from "@/proxies/CollectionProxy";

export default {
  name: "pages",
  data() {
    return {
      loading: false,
      isVisible: false,
      filters: {
        page: 1,
        keyword: "",
        rowsPerPage: 5,
        sort: {
          name: ["record_id"],
          desc: [false],
        },
        categoryId: [],
      },
      search_keyword: "",

      dialog: false,
      selected: {},
      headers: [
        {
          text: "#",
          align: "end",
          value: "record_id",
          width: 90,
        },
        {
          text: this.$t('Name'),
          align: "start",
          value: "title",
        },

        { text: this.$t('Project'), value: "rel_project_label" },
        { text: this.$t('Created'), value: "rel_created_by_label", width: 170 },
        { text: this.$t('Last Revision'), value: "created_on", width: 165 },
      ],
      pages: [],
      projects: [],
      projects_divided: [],
      categories: [],
      total: 0,
      pageDetail: {},
      model: [],
      breadcrumbs: [],
    };
  },
  created() {
    this.getProjects();
  },
  watch: {
    model() {
      this.getPages();
    },

    filters: {
      immediate: true,
      deep: true,
      handler: "getPages",
    },
  },
  methods: {
    openPage(item) {
      this.selected = item;
      this.$router.replace({ path: "pages", query: {page: item.record_id} })
      new PageProxy()
        .setParameters({
          pageId: item.record_id,
          id: item.record_id,
        })
        .all()
        .then((response) => {
          this.pageDetail = response.data;

          this.breadcrumbs = []
          if(this.pageDetail.rel_parent_page__parent_page){
              this.breadcrumbs.push(
                {
                  text: this.pageDetail.rel_parent_page__parent_page_label,
                  disabled: false,
                  link:false,
                  replace:true,
                  // href: 'pages?page='+ this.pageDetail.rel_parent_page__parent_page,
                }
              );
          } if(this.pageDetail.parent_page){
              this.breadcrumbs.push(
                {
                  text: this.pageDetail.rel_parent_page_label,
                  disabled: false,
                  link:false,
                  replace:true,

                  // href: ()=>{
                  //     this.openPage({record_id:this.pageDetail.parent_page.val})
                  // },
                }
              );
          }  if(this.pageDetail.title){
              this.breadcrumbs.push(
                {
                  text: this.pageDetail.title,
                  disabled: true,
                  link:false,

                  // href: 'pages?page='+this.pageDetail.record_id,
                }
              );
          }
          this.dialog = true;
        });
    },
    close(){
      this.dialog = false;
      this.$router.replace({ path: "pages", query: {} })
    },
    getProjects() {
      new ProjectProxy().all().then((response) => {
        this.projects = response.data;
      });
    },

    addToList(list, id, label, paent) {
      let found = list.find((b) => {
        return b.id == id;
      });

      if (!found?.id) {
        let found2 = {
          id: id,
          name: label,
          parent: paent,
          children: [],
        };
        list.push(found2);
      }
      return list;
    },
    getPages() {
      this.loading = true;
      let parameters = { ...this.filters };

      parameters.projectId = this.model.map((k) => {
        return k.record_id;
      });

      this.categories = [];
      new PagesProxy()
        .setParameters(parameters)
        .all()
        .then((response) => {
          this.pages = response.data;
          this.total = response.totalCount ? response.totalCount : 10;
          this.loading = false;

          // parent_page
          // rel_parent_page_label
          // rel_parent_page__rel_parent_page_label
          // rel_parent_page__parent_page
          let pages = [];
          this.pages.forEach((a) => {
            if (a.rel_parent_page__parent_page) {
              pages = this.addToList(
                pages,
                a.rel_parent_page__parent_page,
                a.rel_parent_page__rel_parent_page_label,
                null
              );
            }
            if (a.parent_page) {
              pages = this.addToList(
                pages,
                a.parent_page,
                a.rel_parent_page_label,
                a.rel_parent_page__parent_page
              );
            }
            pages = this.addToList(pages, a.record_id, a.title, a.parent_page);

            pages.forEach((k) => {
              if (k.parent) {
                let found = pages.find((a) => a.id == k.parent);

                if (found) {
                  let found2 = found.children.find((b) => {
                    return b.id == k.id;
                  });
                  if (!found2) {
                    found.children.push(k);
                  }
                }
              }
            });

            let ct = [];
            pages.forEach((k) => {
              if (k.parent === null) {
                ct.push(k);
              }
            });
            this.categories = ct;
          });

          if (this.filters.page != 1 && this.response.totalCount == 0) {
            this.filters.page = 1;
            this.getPages();
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  background: #ebeef2;
  max-width: 500px;
  height: calc(100vh - 110px);
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  position: fixed;
  width: 270px;

  h3 {
    color: #465669;
    font-size: 18px;
    font-weight: 600;
    line-height: 3;
  }

  h4 {
    color: #465669;
    font-size: 14px;
    font-weight: 600;

    line-height: 2;
  }
  .v-list.theme--light {
    background: none;
  }

  .v-list {
    max-height: 250px;
    overflow: auto;
  }
  .v-list-item {
    flex-direction: row-reverse;
    height: 30px;
    min-height: 30px;
    padding: 0px;
  }

  .v-list-item__action {
    margin-left: 0 !important;
    margin: 2px 16px 2px 0px;
  }
  .v-list-item__title {
    align-self: center;
    font-size: 0.8rem;
    color: #465669;
  }
}

.page-detail-title {
  border-bottom: 1px solid #efefef;
  width: 100%;
  padding: 0px 5px 5px 5px;
}
.page-detail-subtitle {
  padding: 5px;
  font-weight: 400;
  font-size: 13px;
}
</style>
<style>
.v-text-field--outlined fieldset {
  background: #fff !important;
}

.page-table .v-data-table__wrapper {
  min-height: calc(100vh - 260px);
}


.v-breadcrumbs {
    padding: 12px 12px;
}
</style>
