import { render, staticRenderFns } from "./Pages.vue?vue&type=template&id=49fc2560&scoped=true&"
import script from "./Pages.vue?vue&type=script&lang=js&"
export * from "./Pages.vue?vue&type=script&lang=js&"
import style0 from "./Pages.vue?vue&type=style&index=0&id=49fc2560&lang=scss&scoped=true&"
import style1 from "./Pages.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49fc2560",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VBreadcrumbs,VBtn,VCard,VCardTitle,VCheckbox,VCol,VDataTable,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemGroup,VListItemTitle,VRow,VSpacer,VTextField,VTreeview})
