var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"projects container-fluid align-center px-6 pt-6 pb-0"},[_c('v-row',{attrs:{"gutters":3,"pa-2":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],staticClass:"ticket-overlay",on:{"click":function($event){_vm.isVisible=!_vm.isVisible}}}),_c('v-col',{staticClass:"sidebar-container",staticStyle:{"max-width":"300px !important"}},[_c('div',{staticClass:"sidebar",class:_vm.isVisible ? 'visible':''},[_c('h3',[_vm._v(_vm._s(_vm.$t('Pages')))]),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_vm._l((_vm.projects),function(item,i){return [_c('v-list-item',{key:("item-" + i),attrs:{"value":item,"active-class":"primary--text text--accent-4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.project_name)}})],1),_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary accent-4"}})],1)]}}],null,true)})]})],2)],1),_c('h4',[_vm._v(_vm._s(_vm.$t('Categories')))]),(_vm.categories[0])?_c('v-treeview',{attrs:{"selectable":"","item-disabled":"locked","items":_vm.categories},model:{value:(_vm.filters.categoryId),callback:function ($$v) {_vm.$set(_vm.filters, "categoryId", $$v)},expression:"filters.categoryId"}}):_vm._e()],1)]),_c('v-col',[(!_vm.dialog)?_c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"btn-mobile",attrs:{"color":"primary","depressed":"","large":""},on:{"click":function($event){_vm.isVisible=!_vm.isVisible}}},[_vm._v(" FILTER ")]),_c('v-spacer'),_c('div',{staticStyle:{"margin-left":"10px","margin-top":"-12px"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('Search'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filters.keyword),callback:function ($$v) {_vm.$set(_vm.filters, "keyword", $$v)},expression:"filters.keyword"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 page-table",attrs:{"headers":_vm.headers,"items":_vm.pages,"page":_vm.filters.page,"items-per-page":_vm.filters.rowsPerPage,"sort-by":_vm.filters.sort.name,"sort-desc":_vm.filters.sort.desc,"server-items-length":_vm.total,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': [25, 50, 100],
          }},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filters.sort, "name", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filters.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"item.record_id",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openPage(item)}}},[_vm._v(" "+_vm._s(item.record_id)+" - "+_vm._s(_vm.total)+" ")])]}},{key:"item.title",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openPage(item)}}},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.rel_created_by_label",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.rel_created_by_label)+" ")]}}],null,false,2367482989)},[_vm._v(" > ")])],1):_c('div',[_c('v-btn',{attrs:{"color":"primary","depressed":"","rounded":"","small":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(_vm._s(_vm.$t('Back to Pages'))+" ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"page-detail-title"},[_c('h3',[_c('i',{staticClass:"fas fa-books"}),_vm._v(" "+_vm._s(_vm.selected.title))])]),_c('div',{staticClass:"page-detail-subtitle"},[_vm._v(" "+_vm._s(_vm.$t('Created by:'))+" "+_vm._s((_vm.pageDetail.last_modified_by && _vm.pageDetail.last_modified_by.label) || (_vm.pageDetail.created_by && _vm.pageDetail.created_by.label))+" on "+_vm._s(_vm.pageDetail.last_modified_on || _vm.pageDetail.created_on)+" ")])]),(_vm.pageDetail.page_content)?_c('div',{staticClass:"pa-6",domProps:{"innerHTML":_vm._s(_vm.pageDetail.page_content)}}):_c('div',{staticClass:"pa-12"},[_vm._v(_vm._s(_vm.$t('Content is empty.')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }